import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";

const card = {
  width: 50,
  height: 90,
  border: "1px solid",
  margin: 10,
  cursor: "pointer",
  fontWeight: "bold",
};
const playersNumStyle = {
  width: 40,
  height: 40,
  border: "1px solid",
  margin: 10,
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
};
const row = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
};

function App() {
  const [cardToSet, setCardToSet] = useState(null);
  const [cardTemp, setCardTemp] = useState(null);
  const [suiteTemp, setSuiteTemp] = useState(null);
  const [maxPlayersNum, setMaxPlayersNum] = useState(null);
  const [isShowMaxPlayersPopup, setIsShowMaxPlayersPopup] = useState(false);
  const [playersNum, setPlayersNum] = useState(null);
  const [color, setColor] = useState("white");
  const [gameCards, setGameCards] = useState([]);
  const [query, setQuery] = useState("");
  const [winRate, setWinrate] = useState("");

  const startHand = {
    AAD: {
      color: "red",
    },
    AKD: {
      color: "red",
    },
    AQD: {
      color: "yellow",
    },
    AJD: {
      color: "yellow",
    },
    ATD: {
      color: "blue",
    },
    A9D: {
      color: "blue",
    },
    A8D: {
      color: "blue",
    },
    A7D: {
      color: "green",
    },
    A6D: {
      color: "green",
    },
    A5D: {
      color: "green",
    },
    A4D: {
      color: "green",
    },
    A3D: {
      color: "green",
    },
    A2D: {
      color: "green",
    },
    AKS: {
      color: "green",
    },
    AKS: {
      color: "red",
    },
    AQS: {
      color: "red",
    },
    AJS: {
      color: "yellow",
    },
    ATS: {
      color: "yellow",
    },
    A9S: {
      color: "yellow",
    },
    A8S: {
      color: "blue",
    },
    A7S: {
      color: "blue",
    },
    A6S: {
      color: "blue",
    },
    A5S: {
      color: "green",
    },
    A4S: {
      color: "green",
    },
    A3S: {
      color: "green",
    },
    A2S: {
      color: "green",
    },
    KKD: {
      color: "blue",
    },
    KQD: {
      color: "blue",
    },
    KJD: {
      color: "blue",
    },
    KTD: {
      color: "blue",
    },
    K9D: {
      color: "green",
    },
    KQS: {
      color: "yellow",
    },
    QQD: {
      color: "red",
    },
    QJD: {
      color: "blue",
    },
    QTD: {
      color: "green",
    },
    Q9D: {
      color: "green",
    },
    KJS: {
      color: "blue",
    },
    QJS: {
      color: "yellow",
    },
    JJD: {
      color: "red",
    },
    JTD: {
      color: "green",
    },
    KTS: {
      color: "blue",
    },
    QTS: {
      color: "blue",
    },
    JTS: {
      color: "blue",
    },
    TTD: {
      color: "yellow",
    },
    T9D: {
      color: "green",
    },
    K9S: {
      color: "blue",
    },
    Q9S: {
      color: "green",
    },
    J9S: {
      color: "green",
    },
    T9S: {
      color: "green",
    },
    l99D: {
      color: "yellow",
    },
    l98D: {
      color: "green",
    },
    K8S: {
      color: "green",
    },
    Q8S: {
      color: "green",
    },
    J8S: {
      color: "green",
    },
    T8S: {
      color: "green",
    },
    l98S: {
      color: "green",
    },
    l88D: {
      color: "yellow",
    },
    K7S: {
      color: "green",
    },
    l97S: {
      color: "green",
    },
    l87S: {
      color: "green",
    },
    l77D: {
      color: "yellow",
    },
    l86S: {
      color: "green",
    },
    l76S: {
      color: "green",
    },
    l66D: {
      color: "blue",
    },
    l75S: {
      color: "green",
    },
    l65S: {
      color: "green",
    },
    l55D: {
      color: "blue",
    },
    l54S: {
      color: "green",
    },
    l44D: {
      color: "blue",
    },
    l33D: {
      color: "blue",
    },
    l22D: {
      color: "blue",
    },
  };

  const players = ["2", "3", "4", "5", "6", "7", "8", "9"];

  const cards = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "T",
    "J",
    "Q",
    "K",
    "A",
  ];

  const suits = ["h", "d", "c", "s"];

  useEffect(() => {
    if (cardTemp && suiteTemp && cardToSet) {
      setGameCards([
        ...gameCards.filter((g) => g.cardNum !== cardToSet),
        {
          cardNum: cardToSet,
          card: `${cardTemp}${suiteTemp}`,
          cardTemp: cardTemp,
          suiteTemp: suiteTemp,
        },
      ]);
      setCardToSet(null);
      setCardTemp(null);
      setSuiteTemp(null);
    }
  }, [cardTemp, cardToSet, suiteTemp]);

  const isNumber = (val) => {
    return !isNaN(val);
  };

  useEffect(() => {
    //console.log("gameCards", gameCards);

    const card1 = gameCards.find((g) => g.cardNum === 1);
    const card2 = gameCards.find((g) => g.cardNum === 2);
    const card3 = gameCards.find((g) => g.cardNum === 3);
    const card4 = gameCards.find((g) => g.cardNum === 4);
    const card5 = gameCards.find((g) => g.cardNum === 5);
    const card6 = gameCards.find((g) => g.cardNum === 6);
    const card7 = gameCards.find((g) => g.cardNum === 7);

    if (card1 && card2) {
      const ind = card1.suiteTemp === card2.suiteTemp ? "S" : "D";
      const abbr1 = `${isNumber(card1.cardTemp) ? "l" : ""}${card1.cardTemp}${
        card2.cardTemp
      }${ind}`;
      const abbr2 = `${isNumber(card2.cardTemp) ? "l" : ""}${card2.cardTemp}${
        card1.cardTemp
      }${ind}`;

      if (startHand?.[abbr1]?.color || startHand?.[abbr2]?.color) {
        setColor(startHand?.[abbr1]?.color);
      } else {
        setColor("white");
      }

      console.log("abbr1", abbr1);
      console.log("abbr2", abbr2);
    }

    if (playersNum && card1 && card2 && card3 && card4 && card5) {
      const myCards = [];
      myCards.push(`${card1.cardTemp}${card1.suiteTemp}`);
      myCards.push(`${card2.cardTemp}${card2.suiteTemp}`);
      myCards.sort();

      const nextCards = [];
      nextCards.push(`${card3.cardTemp}${card3.suiteTemp}`);
      nextCards.push(`${card4.cardTemp}${card4.suiteTemp}`);
      nextCards.push(`${card5.cardTemp}${card5.suiteTemp}`);

      if (card6) {
        nextCards.push(`${card6.cardTemp}${card6.suiteTemp}`);
      }
      if (card7) {
        nextCards.push(`${card7.cardTemp}${card7.suiteTemp}`);
      }
      nextCards.sort();

      const q = `${myCards[0]}|${myCards[1]}|${Number(playersNum) - 1}|${
        nextCards[0]
      }|${nextCards[1]}|${nextCards[2]}|${
        nextCards?.[3] ? nextCards?.[3] : ""
      }|${nextCards?.[4] ? nextCards?.[4] : ""}|1|1`;

      setQuery(q);
    } else {
      setQuery("");
    }
  }, [gameCards, playersNum]);

  useEffect(() => {
    if (query) {
      fetch(`https://hook.ugolshteina.com/api/v1/pattern/poker`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: query }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success" && data.response.includes("<win>")) {
            const win = data.response.split("<win>");
            const winf = win[1].split("</win>")[0];
            console.log("win", winf);
            setWinrate(winf);
          } else {
            setWinrate("");
          }
        })
        .catch((error) => {
          setWinrate("");
          console.log(error);
        });
    } else {
      setWinrate("");
    }
  }, [query]);

  return (
    <div className="App">
      {(!maxPlayersNum || isShowMaxPlayersPopup) && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            backgroundColor: "white",
            zIndex: 10,
          }}
        >
          <div style={row}>
            {players.map((p) => (
              <div
                key={p}
                style={{
                  ...playersNumStyle,
                  backgroundColor: maxPlayersNum === p ? "red" : undefined,
                }}
                onClick={() => {
                  setMaxPlayersNum(p);
                  setPlayersNum(p);
                }}
              >
                <p style={{ color: maxPlayersNum === p ? "white" : undefined }}>
                  {p}
                </p>
              </div>
            ))}
          </div>

          <div
            onClick={() => setIsShowMaxPlayersPopup(false)}
            style={{
              bottom: 0,
              position: "absolute",
              width: "100%",
              backgroundColor: "red",
              cursor: "pointer",
            }}
          >
            <p style={{ color: "white" }}>ЗАКРЫТЬ</p>
          </div>
        </div>
      )}
      {cardToSet && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            backgroundColor: "white",
            zIndex: 11,
          }}
        >
          {!cardTemp && !suiteTemp ? (
            <div style={{ ...row, flexWrap: "wrap" }}>
              {cards.map((c) => (
                <div
                  key={c}
                  onClick={() => setCardTemp(c)}
                  style={{
                    ...card,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "bold",
                  }}
                >
                  {c === "T" ? 10 : c}
                </div>
              ))}
            </div>
          ) : (
            <>
              <div style={{ ...row, flexWrap: "wrap" }}>
                {suits.map((s) => (
                  <div
                    key={s}
                    onClick={() => setSuiteTemp(s)}
                    style={{
                      ...card,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      fontWeight: "bold",
                    }}
                  >
                    {s && (
                      <p>
                        <img src={`${s}.png`} style={{ width: 20 }} />
                      </p>
                    )}
                  </div>
                ))}
              </div>
              <div
                onClick={() => {
                  setCardTemp(null);
                  setSuiteTemp(null);
                }}
                style={{
                  width: "100%",
                  backgroundColor: "red",
                  cursor: "pointer",
                  height: 50,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <p style={{ color: "white" }}>Назад</p>
              </div>
            </>
          )}

          <div
            onClick={() => {
              setGameCards([
                ...gameCards.filter((g) => g.cardNum !== cardToSet),
              ]);
              setCardToSet(null);
            }}
            style={{
              bottom: 80,
              position: "absolute",
              width: "100%",
              backgroundColor: "blue",
              cursor: "pointer",
            }}
          >
            <p style={{ color: "white" }}>ОЧИСТИТЬ КАРТУ</p>
          </div>

          <div
            onClick={() => setCardToSet(null)}
            style={{
              bottom: 0,
              position: "absolute",
              width: "100%",
              backgroundColor: "red",
              cursor: "pointer",
            }}
          >
            <p style={{ color: "white" }}>ЗАКРЫТЬ</p>
          </div>
        </div>
      )}
      <div style={{ width: "100%", height: "100vh" }}>
        <div style={row}>
          {players.map(
            (p) =>
              Number(p) <= Number(maxPlayersNum) && (
                <div
                  key={p}
                  style={{
                    ...playersNumStyle,
                    backgroundColor: playersNum === p ? "red" : undefined,
                  }}
                  onClick={() => setPlayersNum(p)}
                >
                  <p style={{ color: playersNum === p ? "white" : undefined }}>
                    {p}
                  </p>
                </div>
              )
          )}
        </div>
        <div style={row}>
          <div onClick={() => setCardToSet(1)} style={card}>
            <p>
              {gameCards.find((g) => g.cardNum === 1)?.cardTemp === "T"
                ? 10
                : gameCards.find((g) => g.cardNum === 1)?.cardTemp}
            </p>
            {gameCards.find((g) => g.cardNum === 1)?.suiteTemp && (
              <p>
                <img
                  src={`${
                    gameCards.find((g) => g.cardNum === 1)?.suiteTemp
                  }.png`}
                  style={{ width: 20 }}
                />
              </p>
            )}
          </div>
          <div onClick={() => setCardToSet(2)} style={card}>
            <p>
              {gameCards.find((g) => g.cardNum === 2)?.cardTemp === "T"
                ? 10
                : gameCards.find((g) => g.cardNum === 2)?.cardTemp}
            </p>
            {gameCards.find((g) => g.cardNum === 2)?.suiteTemp && (
              <p>
                <img
                  src={`${
                    gameCards.find((g) => g.cardNum === 2)?.suiteTemp
                  }.png`}
                  style={{ width: 20 }}
                />
              </p>
            )}
          </div>
        </div>
        <div style={row}>
          <div onClick={() => setCardToSet(3)} style={card}>
            <p>
              {gameCards.find((g) => g.cardNum === 3)?.cardTemp === "T"
                ? 10
                : gameCards.find((g) => g.cardNum === 3)?.cardTemp}
            </p>
            {gameCards.find((g) => g.cardNum === 3)?.suiteTemp && (
              <p>
                <img
                  src={`${
                    gameCards.find((g) => g.cardNum === 3)?.suiteTemp
                  }.png`}
                  style={{ width: 20 }}
                />
              </p>
            )}
          </div>
          <div onClick={() => setCardToSet(4)} style={card}>
            <p>
              {gameCards.find((g) => g.cardNum === 4)?.cardTemp === "T"
                ? 10
                : gameCards.find((g) => g.cardNum === 4)?.cardTemp}
            </p>
            {gameCards.find((g) => g.cardNum === 4)?.suiteTemp && (
              <p>
                <img
                  src={`${
                    gameCards.find((g) => g.cardNum === 4)?.suiteTemp
                  }.png`}
                  style={{ width: 20 }}
                />
              </p>
            )}
          </div>
          <div onClick={() => setCardToSet(5)} style={card}>
            <p>
              {gameCards.find((g) => g.cardNum === 5)?.cardTemp === "T"
                ? 10
                : gameCards.find((g) => g.cardNum === 5)?.cardTemp}
            </p>
            {gameCards.find((g) => g.cardNum === 5)?.suiteTemp && (
              <p>
                <img
                  src={`${
                    gameCards.find((g) => g.cardNum === 5)?.suiteTemp
                  }.png`}
                  style={{ width: 20 }}
                />
              </p>
            )}
          </div>
          <div onClick={() => setCardToSet(6)} style={card}>
            <p>
              {gameCards.find((g) => g.cardNum === 6)?.cardTemp === "T"
                ? 10
                : gameCards.find((g) => g.cardNum === 6)?.cardTemp}
            </p>
            {gameCards.find((g) => g.cardNum === 6)?.suiteTemp && (
              <p>
                <img
                  src={`${
                    gameCards.find((g) => g.cardNum === 6)?.suiteTemp
                  }.png`}
                  style={{ width: 20 }}
                />
              </p>
            )}
          </div>
          <div onClick={() => setCardToSet(7)} style={card}>
            <p>
              {gameCards.find((g) => g.cardNum === 7)?.cardTemp === "T"
                ? 10
                : gameCards.find((g) => g.cardNum === 7)?.cardTemp}
            </p>
            {gameCards.find((g) => g.cardNum === 7)?.suiteTemp && (
              <p>
                <img
                  src={`${
                    gameCards.find((g) => g.cardNum === 7)?.suiteTemp
                  }.png`}
                  style={{ width: 20 }}
                />
              </p>
            )}
          </div>
        </div>
        <div
          style={{
            bottom: 150,
            position: "absolute",
            width: "100%",
          }}
        >
          <p>{winRate}</p>
          <p>{color}</p>
        </div>
        <div
          onClick={() => setIsShowMaxPlayersPopup(true)}
          style={{
            bottom: 80,
            position: "absolute",
            width: "100%",
            backgroundColor: "blue",
            cursor: "pointer",
          }}
        >
          <p style={{ color: "white" }}>МАКС КОЛ-ВО ИГРОКОВ</p>
        </div>
        <div
          onClick={() => {
            setGameCards([]);
            setCardToSet(null);
            setCardTemp(null);
            setSuiteTemp(null);
            setPlayersNum(maxPlayersNum);
          }}
          style={{
            bottom: 0,
            position: "absolute",
            width: "100%",
            backgroundColor: "red",
            cursor: "pointer",
          }}
        >
          <p style={{ color: "white" }}>СБРОСИТЬ</p>
        </div>
      </div>
    </div>
  );
}

export default App;
